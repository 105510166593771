import moment from "moment";
import vi from "moment/locale/vi";

const weekday = [
  "Chủ nhật",
  "Thứ hai",
  "Thứ 3",
  "Thứ 4",
  "Thứ 5",
  "Thứ 6",
  "Thứ bảy",
];
const tsToDate = (
  ts,
  withTime = false,
  withDayOfWeek = false,
  locale = "vi"
) => {
  moment.updateLocale(locale, vi);
  // var date = new Date(ts);
  // var formattedTime = date.toLocaleDateString("vi-VN", {
  //   weekday: "long",
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  //   hour: withTime ? "2-digit" : undefined,
  //   minute: withTime ? "2-digit" : undefined,
  // });
  const timeString = moment(ts).format("HH:mm");
  const dayOfWeekString = moment(ts).format("dddd");
  const dateString = moment(ts).format("DD/MM/YYYY");
  const dayOfWeekUppercase =
    dayOfWeekString.charAt(0).toUpperCase() + dayOfWeekString.substr(1);
  const result = `${withTime ? `${timeString}, ` : ""}${
    withDayOfWeek ? `${dayOfWeekUppercase} ngày ` : ""
  }${dateString}`;
  return result;
};

const getDateFromTs = (ts) => {
  return moment(ts).format("DD-MM-YYYY");
};

const calculateDateDistance = (date1, date2) => {
  var difference = Math.abs(date2 - date1);
  var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;
  var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;
  var minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;
  var secondsDifference = Math.floor(difference / 1000);
  return {
    daysDifference,
    hoursDifference,
    minutesDifference,
    secondsDifference,
  };
};

const getDateDiff = (date) => {
  const diff = calculateDateDistance(date, Date.now());
  if (diff.daysDifference > 0) return `${diff.daysDifference} ngày trước`;
  if (diff.hoursDifference > 0) return `${diff.hoursDifference} giờ trước`;
  if (diff.minutesDifference > 0) return `${diff.minutesDifference} phút trước`;
  if (diff.secondsDifference > 0) return `${diff.secondsDifference} giây trước`;
  return "Bây giờ";
};

function readAbleTime(hours, minutes, seconds) {
  if (!!hours) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  if (!!minutes) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
}

function vnpTimeToTs(vnp_time) {
  if (vnp_time) {
    var date = new Date(
      vnp_time.replace(
        /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
        "$4:$5:$6 $2/$3/$1"
      )
    );
    return date;
  }
}

const formatTimeOut = (time) => {
  let minutes = Math.floor(time / 60);
  let seconds = Math.floor(time - minutes * 60);

  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  return minutes + ":" + seconds;
};

export {
  tsToDate,
  calculateDateDistance,
  getDateDiff,
  readAbleTime,
  vnpTimeToTs,
  getDateFromTs,
  formatTimeOut,
};
