/* eslint-disable no-unused-vars */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./routes/page-routes";
import { useSelector, useDispatch } from "react-redux";
import {
  logout,
  update,
  updateBalance,
  updateBonus,
  updateCurFCMToken,
} from "./redux-toolkit/authSlice";
import { BarWave } from "react-cssfx-loading";
import Loading from "./components/loading/Loading";
import { createTheme } from "@mui/material";
import { getProfileInfo, getUserBonus } from "./utils/profileHelper";
import { WS_API, WS_EVENT } from "./common/api";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import {
  getFirebaseToken,
  onForegroundMessage,
} from "./firebaseNotif/firebase";
import { signOut } from "./utils/authHelper";
import { PAGE_PATH } from "./routes/page-paths";
import { SocketContext, useSocketContext } from "./contexts/socketContext";
import {
  getListNoti,
  handleReceiveNotiBaseOnType,
  updateNotiToken,
} from "./utils/notificationHelper";
import { useNotificationContext } from "./contexts/notificationContext";
import { CustomToastWithLink } from "./module/notification/Notification";
import { NOTI_PER_PAGE, ROLE } from "./utils/constant";
import { NOTIFICATION_TYPE } from "./common/constants";
import { getDomainWs } from "./utils/socketHelper";
import RESP from "./common/respCode";
import { useQueryClient } from "react-query";
import { reactQueryKey } from "./utils/fetch";

const DELAY_TIME = 500;

const Protected = lazy(() =>
  Promise.all([
    import("./components/Protected.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

let socket = null;

const NOTIF_TYPE = {
  NEW_MEETING_PLANNED: 1,
  NEW_TA_CREATED: 2,
};

function App() {
  const { user } = useSelector((state) => state.auth);
  const { socket: socketContext, setSocket } = useSocketContext();
  const { notiList, setNotiList } = useNotificationContext();
  const [count, setCount] = useState(0);
  const [isGettingProfile, setIsGettingProfile] = useState(false);
  const [isGettingBonus, setIsGettingBonus] = useState(false);
  const dispatch = useDispatch();
  const getProfile = async () => {
    setIsGettingProfile(true);
    const res = await getProfileInfo();
    console.log(res.info);
    if (res && res.status === 0) {
      console.log("update user");
      dispatch(update(res.info));
    }
    setIsGettingProfile(false);
  };
  const getBonus = async () => {
    setIsGettingBonus(true);
    const res = await getUserBonus();
    if (res && res.status === RESP.SUCCESS) {
      dispatch(updateBonus({ bonus: res.data.curPoints }));
    }
    setIsGettingBonus(false);
  };
  const navigate = useNavigate();

  function handleExprTok() {
    if (user.data && user.tokenExpr === true) {
      toast.warn("Phiên đăng nhập hết hạn");
      signOut(user?.curFCMToken?.token);
      socket?.disconnect();
      setSocket(null);
      dispatch(logout());
      navigate(PAGE_PATH.LOGIN);
    }
  }
  const queryClient = useQueryClient();

  // Check token expr when code 400 return on request
  useEffect(() => {
    if (!user.tokenExpr && user?.data && user?.data?.role === ROLE.TA) {
      getBonus();
    }

    handleExprTok();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.tokenExpr]);

  // useEffect(() => {
  //   if (!user?.data) return;
  //   if (isGettingBonus) return;
  //   if (isGettingProfile) return;
  //   try {
  //     getFirebaseToken()
  //       .then(async (firebaseToken) => {
  //         const newUser = { ...user };
  //         console.log("Firebase token: ", firebaseToken);
  //         if (!firebaseToken) return;
  //         if (firebaseToken === user?.curFCMToken?.token) return;
  //         const oldToken = user?.curFCMToken?.token;
  //         const res = await updateNotiToken(oldToken, firebaseToken);
  //         newUser.curFCMToken = {
  //           inUse: true,
  //           token: firebaseToken,
  //           ts: Date.now(),
  //         };
  //         dispatch(updateCurFCMToken(newUser.curFCMToken));
  //       })
  //       .catch((err) => {
  //         console.error(
  //           "An error occured while retrieving firebase token. ",
  //           err
  //         );
  //         const newUser = { ...user };
  //         newUser.curFCMToken = {
  //           ...newUser.curFCMToken,
  //           inUse: false,
  //         };
  //         dispatch(updateCurFCMToken(newUser.curFCMToken));
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [user.data]);

  useEffect(() => {
    if (
      !user ||
      !user.data ||
      !user.data.id ||
      (user.curFCMToken &&
        (user.curFCMToken.ts !== 0 || user.curFCMToken.token !== null))
    ) {
      console.log("STOPPPPPPPPP");
      return;
    }
  }, []);
  // Xử lí nhận foreground message
  useEffect(() => {
    console.log("Foreground message useEffect");
    if (!user?.curFCMToken?.inUse) return;
    onForegroundMessage()
      .then((payload) => {
        console.log("Received foreground message: ", payload);
        const { data } = payload;
        const { title, body } = data;
        const object = JSON.parse(body);
        const newNotiList = [JSON.parse(body), ...notiList];
        console.log("body", object);
        console.log("toast foreground message");
        toast(CustomToastWithLink(JSON.parse(body)), {
          hideProgressBar: true,
        });
        setNotiList([...newNotiList]);
        // Update số dư
        handleReceiveNotiBaseOnType(dispatch, object);
        if (object?.type === NOTIFICATION_TYPE.ADDING_MONEY_SUCCESS) {
          queryClient.invalidateQueries(reactQueryKey.TRANSACTION_LIST());
        }
        // if (object.type === NOTIFICATION_TYPE.ADDING_MONEY_SUCCESS) {
        //   console.log(
        //     "update balance add money",
        //     object?.notiObj?.remainingBalance
        //   );
        //   dispatch(
        //     updateBalance({ balance: object?.notiObj?.remainingBalance })
        //   );
        // } else if (object.type === NOTIFICATION_TYPE.PAID_MEETING) {
        //   console.log(
        //     "update blance paid meeting",
        //     object?.notiObj?.remainingBalance
        //   );
        //   dispatch(
        //     updateBalance({ balance: object?.notiObj?.remainingBalance })
        //   );
        // }
      })
      .catch((err) =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err
        )
      );
  }, [notiList, user?.curFCMToken?.inUse]);
  // Xử lí nhận background message
  useEffect(() => {
    console.log("Background message useEffect");
    if (!user?.curFCMToken?.inUse) return;
    const channel = new window.BroadcastChannel("sw-messages");
    const handleReceiveBackgroundMessage = async (event) => {
      const { body, title } = event.data.data;
      const object = JSON.parse(body);
      console.log("body", object);
      console.log("toast background message");
      const newNotiList = [JSON.parse(body), ...notiList];
      console.log("toast background message");
      toast(CustomToastWithLink(JSON.parse(body)), {
        hideProgressBar: true,
      });
      setNotiList([...newNotiList]);
      // Update số dư
      handleReceiveNotiBaseOnType(dispatch, object);
      if (object?.type === NOTIFICATION_TYPE.ADDING_MONEY_SUCCESS) {
        queryClient.invalidateQueries(reactQueryKey.TRANSACTION_LIST());
      }
    };
    channel.addEventListener("message", handleReceiveBackgroundMessage);
    return () =>
      channel.removeEventListener("message", handleReceiveBackgroundMessage);
  }, [notiList, user?.curFCMToken?.inUse]);

  // useEffect(() => {
  //   if (isGettingBonus) return;
  //   if (isGettingProfile) return;
  //   if (!user.data || (socketContext && socketContext.connected)) {
  //     return;
  //   }
  //   console.log(getDomainWs() + WS_API.REALTIME_API);
  //   socket = io(getDomainWs() + WS_API.REALTIME_API, {
  //     withCredentials: true,
  //     transports: ["polling"],
  //   });

  //   console.log("connecting", socket);
  //   socket.on("connect", () => {
  //     console.log("connected", socket);
  //     setSocket(socket);
  //     // save websocket connection to context here
  //   });

  //   socket.on("disconnect", () => {
  //     console.log("Disconnected");
  //     setSocket(socket);
  //     // onDisconnect();
  //   });

  //   socket.on("connect_error", (err) => {
  //     console.log(`connect_error due to ${err.message}`);
  //   });
  // }, [user.data]);

  // Xử lí nhận message qua socket
  useEffect(() => {
    const handleReceiveSocketNoti = (arg) => {
      console.log("toast socket message");
      const object = JSON.parse(arg);
      const newNotiList = [JSON.parse(arg), ...notiList];
      setNotiList([...newNotiList]);
      toast(CustomToastWithLink(JSON.parse(arg)), {
        hideProgressBar: true,
      });
      handleReceiveNotiBaseOnType(dispatch, object);
      if (object?.type === NOTIFICATION_TYPE.ADDING_MONEY_SUCCESS) {
        queryClient.invalidateQueries(reactQueryKey.TRANSACTION_LIST());
      }
    };
    if (user) {
      if (Object.keys(user).length === 0) {
        return;
      }
    }
    if (user?.curFCMToken?.inUse) return;
    if (!socket) return;
    socket.on(WS_EVENT.NOTIFICATION_EVENT, handleReceiveSocketNoti);
    return () => {
      socket.off(WS_EVENT.NOTIFICATION_EVENT, handleReceiveSocketNoti);
    };
  }, [notiList, socket, user]);

  useEffect(() => {
    if (user.data) {
      getProfile();
    }
  }, []);

  // Resize observer ignore
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  function listProtectedRoutes(routes) {
    return routes.map((route) => {
      const Layout = route.layout;
      const Page = route.component;
      const Path = typeof route.path === "function" ? route.path() : route.path;
      return (
        <Route
          key={Path}
          path={Path}
          element={
            // No layout page
            route.noLayout ? (
              <Protected
                isLoggedIn={user?.data}
                isStopWhenLogon={route.stopWhenLogin}
                userRole={user?.data?.role}
                pageRole={route.role}
              >
                <Page />
              </Protected>
            ) : (
              // With layout page
              <Layout>
                <Protected
                  isLoggedIn={user?.data}
                  isStopWhenLogon={route.stopWhenLogin}
                  userRole={user?.data?.role}
                  pageRole={route.role}
                >
                  <Page />
                </Protected>
              </Layout>
            )
          }
        />
      );
    });
  }

  function listPublicRoutes(routes) {
    return routes.map((route) => {
      const Layout = route.layout;
      const Page = route.component;
      const Path = typeof route.path === "function" ? route.path() : route.path;
      return (
        <Route
          key={Path}
          path={Path}
          element={
            route.noLayout ? (
              <Page />
            ) : (
              <Layout>
                <Page />
              </Layout>
            )
          }
        />
      );
    });
  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {/*================================= stop when NOT logon ====================================*/}
        {/* Resource thêm điều kiện kiểm tra role của user là admin hay ko ở đây */}
        {listProtectedRoutes(protectedRoutes)}

        {/*================================= No lock ====================================*/}
        {listPublicRoutes(publicRoutes)}

        {/*================================= Invalid path ====================================*/}
        <Route
          path="*"
          element={<Navigate to={PAGE_PATH.NOT_FOUND} replace />}
        />
      </Routes>
    </Suspense>
  );
}

export default App;
