import { useEffect, useState } from "react";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { Avatar, CircularProgress, Skeleton, Tooltip } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PaidIcon from "@mui/icons-material/Paid";
import EditIcon from "@mui/icons-material/Edit";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DraftsIcon from "@mui/icons-material/Drafts";
import WalletIcon from "@mui/icons-material/Wallet";
import RedeemIcon from "@mui/icons-material/Redeem";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import TaskIcon from "@mui/icons-material/Task";
import { markAsRead } from "../../utils/notificationHelper";
import { getDateDiff, tsToDate } from "../../utils/timeHelper";
import "./notification.css";

const ALL_TAB = 0;
const UNREAD_TAB = 1;
const tabs = [
  { value: ALL_TAB, name: "Tất cả" },
  // {
  //   value: UNREAD_TAB,
  //   name: "Chưa đọc",
  // },
];
export const NotificationDropdown = ({
  coords,
  notiList,
  isLoading,
  isLoadingMore,
  hasMore,
  notiPerPage,
  handleLoadMoreNoti,
  handleMarkAllAsRead,
  handleOnClickNotiDropdownItem,
}) => {
  const [currentTab, setCurrentTab] = useState(ALL_TAB);

  const onTabSelect = (value) => {
    setCurrentTab(value);
    console.log(value);
  };
  // const handleMarkAllAsRead = async () => {
  //   // const res = await markAsRead({ isAll: false });
  // };
  const handleScroll = async (e) => {
    const bottom =
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) ===
      e.target.clientHeight;
    if (bottom && hasMore) {
      // this.setState({ offset: offset + limit }, this.fetchAPI);
      console.log("reach bottom");
      await handleLoadMoreNoti();
    }
  };
  const renderNoti = (item) => {
    switch (item.type) {
      case NOTIFICATION_TYPE.TA_CREATE_ACCOUNT:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Avatar src={item?.notiObj?.avt}></Avatar>
                <div className="text-sm max-w-[370px]">
                  <h1 className="line-clamp-3">
                    Địa chỉ email
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.taEmail}{" "}
                    </span>
                    đã đăng ký tài khoản trợ giảng thành công với tên
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.name}
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <PersonAddIcon color="success" fontSize="medium"></PersonAddIcon>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.SUCCESSFUL_BOOKED_A_MEETING:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Avatar src={item?.notiObj?.avt}></Avatar>
                <div className="text-sm max-w-[370px]">
                  <h1 className="line-clamp-3">
                    {item?.notiObj?.isStudent ? (
                      <>
                        Học viên
                        <span className="font-semibold">
                          {" "}
                          {item?.notiObj?.name}{" "}
                        </span>
                        đã đặt lịch hẹn thành công với bạn. Cuộc hẹn sẽ bắt đầu
                        vào lúc
                        <span className="font-semibold">
                          {" "}
                          {tsToDate(item?.notiObj?.startTs, true, true)}{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        Bạn đã đặt lịch hẹn thành công với{" "}
                        <span className="font-semibold">
                          {" "}
                          {item?.notiObj?.name}{" "}
                        </span>
                        . Cuộc hẹn sẽ bắt đầu vào lúc
                        <span className="font-semibold">
                          {" "}
                          {tsToDate(item?.notiObj?.startTs, true, true)}{" "}
                        </span>
                      </>
                    )}
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <EventAvailableIcon
                color="success"
                fontSize="medium"
              ></EventAvailableIcon>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.CANCEL_MEETING:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Avatar src={item?.notiObj?.avt}></Avatar>
                <div className="text-sm max-w-[370px]">
                  <h1 className="line-clamp-3">
                    {item?.notiObj?.isStudent ? (
                      <>
                        Cuộc hẹn ở khoá học{" "}
                        <span className="font-semibold">
                          {" "}
                          {item?.notiObj?.courseName}{" "}
                        </span>{" "}
                        vào lúc{" "}
                        <span className="font-semibold">
                          {" "}
                          {tsToDate(item?.notiObj?.startTs, true, true)}{" "}
                        </span>{" "}
                        với học viên
                        <span className="font-semibold">
                          {" "}
                          {item?.notiObj?.name}{" "}
                        </span>
                        đã bị huỷ.
                      </>
                    ) : (
                      <>
                        Cuộc hẹn ở khoá học{" "}
                        <span className="font-semibold">
                          {" "}
                          {item?.notiObj?.courseName}{" "}
                        </span>{" "}
                        vào lúc{" "}
                        <span className="font-semibold">
                          {" "}
                          {tsToDate(item?.notiObj?.startTs, true, true)}{" "}
                        </span>{" "}
                        với{" "}
                        <span className="font-semibold">
                          {" "}
                          {item?.notiObj?.name}{" "}
                        </span>
                        đã bị huỷ.
                      </>
                    )}
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <EventBusyIcon color="error" fontSize="medium"></EventBusyIcon>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.WITHDRAW:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <WalletIcon
                  // color="success"
                  sx={{ fontSize: "40px" }}
                ></WalletIcon>
                <div className="text-sm max-w-[320px]">
                  <h1 className="line-clamp-3">
                    Bạn đã rút tiền thành công thông qua Admin{" "}
                    <span className="font-semibold inline-flex items-baseline">
                      <Avatar
                        className="!h-5 !w-5 mx-1"
                        src={item?.notiObj?.traderAvt}
                      ></Avatar>{" "}
                      <span>{item?.notiObj?.traderName}</span>
                    </span>
                    . Số dư tài khoản hiện tại của bạn là{" "}
                    <span className="font-semibold">
                      {" "}
                      {new Intl.NumberFormat("vi-VN").format(
                        item?.notiObj?.remainingBalance
                      )}
                      đ
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <h1 className="font-semibold">
                -{new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}đ
              </h1>
            </div>
            {/* <EventAvailableIcon
          color="success"
          fontSize="medium"
        ></EventAvailableIcon> */}
          </>
        );
      case NOTIFICATION_TYPE.EXCHANGE_FROM_BONUS_TO_MONEY:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <RedeemIcon sx={{ fontSize: "40px" }}></RedeemIcon>
                <div className="text-sm max-w-[320px]">
                  <h1 className="line-clamp-3">
                    Bạn đã đổi{" "}
                    <span className="font-semibold">
                      {item.notiObj.points} điểm
                    </span>{" "}
                    bonus thành số tiền{" "}
                    <span className="font-semibold ">
                      {new Intl.NumberFormat("vi-VN").format(
                        item?.notiObj?.amount
                      )}
                      đ
                    </span>
                    . Số điểm bonus còn lại là{" "}
                    <span className="font-semibold">
                      {item?.notiObj?.remainingPoints} điểm
                    </span>
                    . Số dư tài khoản hiện tại của bạn là{" "}
                    <span className="font-semibold">
                      {new Intl.NumberFormat("vi-VN").format(
                        item?.notiObj?.remainingBalance
                      )}
                      đ
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <div className="text-sm text-end">
                <h1 className="font-semibold text-green-500">
                  +
                  {new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}
                  đ
                </h1>
                <h1 className="font-semibold flex gap-1">
                  -{item.notiObj.points} điểm
                  <RedeemIcon sx={{ fontSize: "18px" }}></RedeemIcon>
                </h1>
              </div>
            </div>
            {/* <EventAvailableIcon
          color="success"
          fontSize="medium"
        ></EventAvailableIcon> */}
          </>
        );
      case NOTIFICATION_TYPE.ADDING_MONEY_SUCCESS:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <WalletIcon
                  color="success"
                  sx={{ fontSize: "40px" }}
                ></WalletIcon>
                <div className="text-sm max-w-[320px]">
                  <h1 className="line-clamp-3">
                    Bạn đã nạp thành công. Số dư tài khoản hiện tại của bạn là{" "}
                    <span className="font-semibold">
                      {" "}
                      {new Intl.NumberFormat("vi-VN").format(
                        item?.notiObj?.remainingBalance
                      )}
                      đ
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <h1 className="font-semibold text-green-500">
                +{new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}đ
              </h1>
            </div>
            {/* <EventAvailableIcon
          color="success"
          fontSize="medium"
        ></EventAvailableIcon> */}
          </>
        );
      case NOTIFICATION_TYPE.PAID_MEETING:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <WalletIcon
                  // color="success"
                  sx={{ fontSize: "40px" }}
                ></WalletIcon>
                <div className="text-sm max-w-[320px]">
                  <h1 className="line-clamp-3">
                    Bạn đã thanh toán thành công cho cuộc hẹn ở khóa học{" "}
                    <span className="font-semibold">
                      {item?.notiObj?.courseName}
                    </span>
                    . Số dư tài khoản hiện tại của bạn là{" "}
                    <span className="font-semibold">
                      {" "}
                      {new Intl.NumberFormat("vi-VN").format(
                        item?.notiObj?.remainingBalance
                      )}
                      đ
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <h1 className="font-semibold">
                -{new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}đ
              </h1>
            </div>
            {/* <EventAvailableIcon
          color="success"
          fontSize="medium"
        ></EventAvailableIcon> */}
          </>
        );
      case NOTIFICATION_TYPE.REFUND_MEETING:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <CurrencyExchangeIcon
                  color="success"
                  sx={{ fontSize: "40px" }}
                ></CurrencyExchangeIcon>
                <div className="text-sm max-w-[320px]">
                  <h1 className="line-clamp-3">
                    Bạn đã được hoàn tiền thành công . Số dư tài khoản hiện tại
                    của bạn là{" "}
                    <span className="font-semibold">
                      {" "}
                      {new Intl.NumberFormat("vi-VN").format(
                        item?.notiObj?.remainingBalance
                      )}
                      đ
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <h1 className="font-semibold text-green-500">
                +{new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}đ
              </h1>
            </div>
            {/* <EventAvailableIcon
            color="success"
            fontSize="medium"
          ></EventAvailableIcon> */}
          </>
        );
      case NOTIFICATION_TYPE.REQUEST_PUBLISH_DRAFT:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Avatar src={item?.notiObj?.avt}></Avatar>
                <div className="text-sm max-w-[370px]">
                  <h1 className="line-clamp-3">
                    Trợ giảng
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.name}{" "}
                    </span>
                    vừa gửi yêu cầu phê duyệt thay đổi thông tin bài học trong
                    khoá học
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.courseName}
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <EditIcon fontSize="medium"></EditIcon>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.APPROVE_PUBLISH_DRAFT:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Avatar src={item?.notiObj?.avt}></Avatar>
                <div className="text-sm max-w-[370px]">
                  <h1 className="line-clamp-3">
                    Quản trị viên
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.name}{" "}
                    </span>
                    vừa phê duyệt một thay đổi thông tin bài học trong khoá học
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.courseName}
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <TaskIcon fontSize="medium" color="success"></TaskIcon>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.REJECT_PUBLISH_DRAFT:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Avatar src={item?.notiObj?.avt}></Avatar>
                <div className="text-sm max-w-[370px]">
                  <h1 className="line-clamp-3">
                    Quản trị viên
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.name}{" "}
                    </span>
                    vừa từ chối một thay đổi thông tin bài học trong khoá học
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.courseName}
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <CancelScheduleSendIcon
                fontSize="medium"
                color="error"
              ></CancelScheduleSendIcon>
            </div>
          </>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <div
      className="absolute bg-white -500 z-50 rounded-md border border-gray-300 shadow-xl w-[570px] notification-dropdown"
      style={{
        // left: coords.left,
        top: coords.height + 8,
        right: -40,
      }}
    >
      <div className="px-6 flex justify-between items-center">
        <h1 className="px-2 py-4 text-lg font-bold">Thông báo</h1>
        <Tooltip title="Đánh dấu đã đọc tất cả" placement="bottom" arrow>
          <div
            className="p-2 cursor-pointer hover:bg-slate-100 rounded-full"
            onClick={handleMarkAllAsRead}
          >
            <DraftsIcon sx={{ color: "gray" }}></DraftsIcon>
          </div>
        </Tooltip>
      </div>
      {/* <div className="px-6 flex justify-between items-center">
        <div className="py-2 flex gap-2">
          {tabs.map((item) => (
            <div
              key={item.value}
              className={`px-4 py-2 cursor-pointer font-semibold rounded-full ${
                currentTab === item.value
                  ? "bg-blue-100 text-blue-500 hover:bg-blue-200"
                  : "  hover:bg-slate-100"
              }`}
              onClick={() => onTabSelect(item.value)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div> */}
      {isLoading ? (
        Array(notiPerPage)
          .fill(0)
          .map((item, index) => (
            <div
              key={index}
              className={`flex justify-between items-center p-4 gap-4 cursor-pointer hover:bg-slate-100 ${
                index !== notiList.length - 1
                  ? "border-b-[1px] border-gray-300"
                  : ""
              } ${index === 0 ? "border-t-[1px] border-gray-300" : ""}`}
            >
              <div className="flex gap-4 justify-center items-center">
                <Skeleton variant="circular" width={40} height={40}></Skeleton>
                <div className="">
                  <div className="w-[400px]">
                    <Skeleton variant="text"></Skeleton>
                  </div>
                  <div className="w-[400px]">
                    <Skeleton variant="text"></Skeleton>
                  </div>
                  <div className="w-[150px]">
                    <Skeleton variant="text"></Skeleton>
                  </div>
                </div>
              </div>
              <Skeleton variant="rectangular" width={20} height={20}></Skeleton>
            </div>
          ))
      ) : (
        <div
          className="max-h-[455px] overflow-y-scroll "
          onScroll={handleScroll}
        >
          {notiList.length > 0 ? (
            <div>
              {notiList.map((item, index) => (
                <div
                  key={item?._id}
                  className={`notification-item  flex w-full items-center pr-4 pl-3 py-4 cursor-pointer ${
                    item?.isUnread
                      ? "bg-blue-100 hover:bg-blue-200"
                      : "hover:bg-slate-100"
                  } ${
                    index !== notiList.length - 1
                      ? "border-b-[1px] border-gray-300"
                      : ""
                  } ${index === 0 ? "border-t-[1px] border-gray-300" : ""}`}
                  onClick={() => handleOnClickNotiDropdownItem(item)}
                >
                  <div
                    className={`flex-none mr-2 h-2 w-2 rounded-full ${
                      item?.isUnread ? "bg-blue-500" : ""
                    }`}
                  ></div>
                  {renderNoti(item)}
                </div>
              ))}
              {isLoadingMore && (
                <div className="p-4 flex justify-center items-center">
                  <CircularProgress></CircularProgress>
                </div>
              )}
            </div>
          ) : (
            <div className="py-8 text-center font-semibold text-gray-300 text-xl">
              Không có thông báo nào
            </div>
          )}
        </div>
      )}
    </div>
  );
};
