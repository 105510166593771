import { API } from "../common/api";
import { NOTIFICATION_TYPE } from "../common/constants";
import { updateBalance } from "../redux-toolkit/authSlice";
import { handleGet, handlePost } from "./fetch";

export const sendNotiFor = async (id, type) => {
  try {
    const res = await handleGet(API.SEND_NOTI_FOR(id, type));
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getListNoti = async (
  page = 1,
  limit = 7,
  isOnlyUnread = null,
  isAsc = true
) => {
  try {
    const res = await handleGet(
      API.GET_LIST_NOTI(page, limit, isOnlyUnread, isAsc)
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const markAsRead = async ({ notiIdList = [], isAll = false }) => {
  try {
    var notiIdListData = notiIdList;
    if (notiIdList.length > 0)
      notiIdListData = notiIdList.map((id) => JSON.stringify(id));
    else {
      notiIdListData = ["1"];
    }
    var data;
    if (isAll) {
      data = {
        isAll: isAll ? 1 : 0,
      };
    } else {
      data = {
        notiIdList: notiIdListData,
      };
    }
    console.log("data", data);
    const res = await handlePost(API.MARK_AS_READ, data);
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const marAsUnred = async (notiId) => {
  try {
    const data = {
      notiId,
    };
    const res = await handlePost(API.MARK_AS_UNREAD, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateNotiToken = async (oldToken = null, newToken) => {
  const data = oldToken
    ? {
        oldToken,
        newToken,
      }
    : { newToken };
  try {
    const res = await handlePost(API.UPDATE_NOTI_TOKEN, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const handleReceiveNotiBaseOnType = (dispatch, object) => {
  switch (object.type) {
    case NOTIFICATION_TYPE.ADDING_MONEY_SUCCESS:
      dispatch(updateBalance({ balance: object?.notiObj?.remainingBalance }));
      break;
    case NOTIFICATION_TYPE.WITHDRAW:
      dispatch(updateBalance({ balance: object?.notiObj?.remainingBalance }));
      break;
    case NOTIFICATION_TYPE.PAID_MEETING:
      dispatch(updateBalance({ balance: object?.notiObj?.remainingBalance }));
      break;
    case NOTIFICATION_TYPE.REFUND_MEETING:
      dispatch(updateBalance({ balance: object?.notiObj?.remainingBalance }));
      break;
    case NOTIFICATION_TYPE.EXCHANGE_FROM_BONUS_TO_MONEY:
    case NOTIFICATION_TYPE.SUCCESSFUL_BOOKED_A_MEETING:
    case NOTIFICATION_TYPE.TA_CREATE_ACCOUNT:
    case NOTIFICATION_TYPE.CANCEL_MEETING:
      return;
    default:
      return;
  }
};
