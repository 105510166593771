import { API } from "../common/api";
import { handlePost } from "./fetch";

export const checkResetPasswordToken = async (token) => {
  try {
    const data = {
      token,
    };
    const res = await handlePost(API.CHECK_RESET_PASSWORD_TOKEN, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const resetPasswordByToken = async (pwd, token) => {
  try {
    const data = {
      pwd,
      token,
    };
    const res = await handlePost(API.RESET_PASSWORD_BY_TOKEN, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const signOut = async (token) => {
  try {
    const data = {
      token,
    };
    await handlePost(API.LOGOUT, data);
  } catch (error) {
    console.log(error);
  }
};

export const verifyAccountByToken = async (token) => {
  try {
    const data = {
      token,
    };
    const res = await handlePost(API.VERIFY_ACCOUNT_BY_TOKEN, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
