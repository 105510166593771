import axios from "axios";
import store from "../redux-toolkit/configureStore";
import { invalidateTok } from "../redux-toolkit/authSlice";

export const handlePost = async (api, data) => {
  try {
    const resp = await axios.post(api, data);
    if (resp.data.code === 400) {
      console.log("🚀 ~ file: fetch.js:9 ~ handlePost ~ resp:", resp.data);
      clearTokken();
    }
    return resp.data;
  } catch (error) {
    console.log("🚀 ~ file: fetch.js:8 ~ handlePost ~ error", error);
  }
};

export const handleGet = async (api) => {
  try {
    const resp = await axios.get(api);
    if (resp.data.code === 400) {
      console.log("🚀 ~ file: fetch.js:18 ~ handleGet ~ resp:", resp.data);
      clearTokken();
    }
    return resp.data;
  } catch (error) {
    console.log("🚀 ~ file: fetch.js ~ line 16 ~ handleGet ~ error", error);
  }
};

export const handlePut = async (api, data) => {
  try {
    const resp = await axios.put(api, data);
    if (resp.data.code === 400) {
      console.log("🚀 ~ file: fetch.js:37 ~ handlePut ~ resp:", resp.data);
      clearTokken();
    }
    return resp;
  } catch (error) {
    console.log(error);
  }
};
export const handlePatch = async (api, data) => {
  try {
    const resp = await axios.patch(api, data);
    if (resp.data.code === 400) {
      console.log("🚀 ~ file: fetch.js:37 ~ handlePatch ~ resp:", resp.data);
      clearTokken();
    }
    return resp.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleDelete = async (api, data) => {
  try {
    const resp = await axios.delete(api, { data });
    if (resp?.data?.code === 400) {
      console.log("🚀 ~ file: fetch.js:53 ~ handleDelete ~ resp:", resp.data);
      clearTokken();
    }
    return resp;
  } catch (error) {
    console.log(error);
  }
};

function clearTokken() {
  // localStorage.clear();
  // handlePost(API.LOGOUT);
  // window.location.href = `${PAGE_PATH.BASE}${PAGE_PATH.LOGIN}`;
  store.dispatch(invalidateTok());
}

export const reactQueryKey = {
  COURSER_CHAPTER: (courseId) =>
    courseId ? ["courseTopic", courseId] : ["courseTopic"],
  TOPIC_LESSON: (topicId) =>
    topicId ? ["topicLesson", topicId] : ["topicLesson"],
  LESSON_RESOURCE: (lessonId) =>
    lessonId ? ["lessonResource", lessonId] : ["lessonResource"],
  MY_COURSE: (page, limit) =>
    page || limit ? ["myCourse", page, limit] : ["myCourse"],
  COURSE_STATISTIC: (page, limit, courseId, uids = "", isOnlyEnrolled = 1) =>
    page || limit || courseId || uids || isOnlyEnrolled
      ? ["courseStatistic", page, limit, courseId, uids, isOnlyEnrolled]
      : ["courseStatistic"],
  LIST_ALL_COURSEs: (page, limit) =>
    page || limit ? ["listAllCourses", page, limit] : ["listAllCourses"],
  //Topic
  TOPIC_LIST: (courseId) =>
    courseId ? ["topicList", courseId] : ["topicList"],

  //Lesson
  LESSON_LIST: (topicId) =>
    topicId ? ["lessonList", topicId] : ["lessonList"],
  // Exercises
  LESSON_EXERCISES: (lessonId) =>
    lessonId ? ["lessonExercise", lessonId] : ["lessonExercise"],

  // Test cases
  HIDDEN_TEST_CASES: (exerciseId) =>
    exerciseId ? ["hiddenTestCases", exerciseId] : ["hiddenTestCases"],

  // Run code
  RUN_CODE_HISTORY: (lessonId, exerciseId) =>
    lessonId && exerciseId
      ? ["runCodeHistory", lessonId, exerciseId]
      : ["runCodeHistory"],
  SUBMIT_CODE_HISTORY: (lessonId, exerciseId) =>
    lessonId && exerciseId
      ? ["submitCodeHistory", lessonId, exerciseId]
      : ["submitCodeHistory"],
  SUBMIT_CODE_INFO: (submitId) =>
    submitId ? ["submitCodeInfo", submitId] : ["submitCodeInfo"],

  // Template
  TEMPLATE_INFO: (lessonId, exerciseId, langId) =>
    lessonId && exerciseId && langId
      ? ["templateInfo", lessonId, exerciseId, langId]
      : ["templateInfo"],

  // Payment
  PAYMENT_RESULTS: (orderId) =>
    orderId ? ["paymentRes", orderId] : "paymentRes",
  TRANSACTION_LIST: (isAsc, page, limit, filterTypes, fromTs, endTs) =>
    isAsc || page || limit || filterTypes || fromTs || endTs
      ? ["transactionList", isAsc, page, limit, filterTypes, fromTs, endTs]
      : ["transactionList"],

  // Profile
  PROFILE: () => ["profile"],

  // Student
  STUDENTS_LIST: (key, page, limit) =>
    key || page || limit ? ["studentList", key, page, limit] : ["studentList"],

  //notes
  NOTE_LIST: (currentPage, limit, courseId, topicIds, lessonIds, isAsc) =>
    currentPage || limit || courseId || topicIds || lessonIds || isAsc
      ? ["noteList", currentPage, limit, courseId, topicIds, lessonIds, isAsc]
      : ["noteList"],

  // Drafts
  LESSONS_DRAFTS: (topicId) =>
    topicId ? ["lessonsDrafts", topicId] : ["lessonsDrafts"],
};
