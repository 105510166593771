export const colors = {
  primary:
    "linear-gradient(90deg,_rgba(4,118,244,1)_0%,_rgba(4,191,253,1)_100%)",
};
export const nav_items = [
  {
    name: "Trang chủ",
    link: "#",
  },
  {
    name: "Khóa học",
    link: "#",
  },
  {
    name: "Liên hệ",
    link: "#",
  },
];
export const ROLE = {
  ADMIN: 0,
  TA: 1,
  STUDENT: 2,
};
export const nav_height = "96";
export const CREATE_COURSE_SUCCESS = 0;
export const CREATE_COURSE_FAILED = 1;
export const NOTI_PER_PAGE = 5;
