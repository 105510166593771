import { useEffect, useState } from "react";
import useClickOutside from "../../hooks/useClickOutSide";
import { useRef } from "react";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationDropdown } from "./NotificationDropdown";
import { NOTIFICATION_TYPE } from "../../common/constants";
import { getListNoti, markAsRead } from "../../utils/notificationHelper";
import { useSocketContext } from "../../contexts/socketContext";
import { useSelector } from "react-redux";
import { getDateDiff, getDateFromTs, tsToDate } from "../../utils/timeHelper";
import { Avatar, Tooltip } from "@mui/material";
import { NOTI_PER_PAGE } from "../../utils/constant";
import { useNotificationContext } from "../../contexts/notificationContext";
import WalletIcon from "@mui/icons-material/Wallet";
import RedeemIcon from "@mui/icons-material/Redeem";
import { CurrencyExchange } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PAGE_PATH } from "../../routes/page-paths";

const LIMIT_INDEX_COUNT_UNREAD = 10;

export const Notification = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const {
    show: showNotification,
    setShow: setShowNotification,
    nodeRef: notiDropdownRef,
  } = useClickOutside();
  const [parentOpen, setParentOpen] = useState(false);
  const [childOpen, setChildOpen] = useState(false);
  const { notiList, setNotiList } = useNotificationContext();
  const [notiCoords, setNotiCoords] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nUnreadNoti, setNUnreadNoti] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const notiRef = useRef(null);
  const handleOpenNotification = () => {
    setNotiCoords(notiRef.current?.getBoundingClientRect());
    setShowNotification(!showNotification);
  };
  const handleLoadMoreNoti = async () => {
    setIsLoadingMore(true);
    const res = await getListNoti(currentPage + 1, NOTI_PER_PAGE, null, false);
    const additionalList = res.data.docs;
    const newList = [...notiList, ...additionalList];
    setNotiList([...newList]);
    setHasMore(res?.data?.hasNextPage);
    setCurrentPage(currentPage + 1);
    setIsLoadingMore(false);
  };
  const handleMarkAllAsRead = async () => {
    const res = await markAsRead({ isAll: true });
    const newList = notiList.map((item) => {
      return { ...item, isUnread: false };
    });
    setNotiList([...newList]);
  };
  const handleOnClickNotiDropdownItem = async (notiItem) => {
    // console.log("notiitem", notiItem);
    const newNotiList = notiList;
    const item =
      newNotiList[newNotiList.findIndex((item) => item._id === notiItem?._id)];
    navigateWhenClickNotiItem(notiItem);
    if (!item.isUnread) return;
    const res = await markAsRead({ notiIdList: [notiItem?._id] });
    if (res.status === 0) {
      newNotiList[
        newNotiList.findIndex((item) => item._id === notiItem?._id)
      ].isUnread = false;
      setNotiList([...newNotiList]);
    }
  };
  const navigateWhenClickNotiItem = (notiItem) => {
    switch (notiItem.type) {
      case NOTIFICATION_TYPE.SUCCESSFUL_BOOKED_A_MEETING:
        const date = getDateFromTs(notiItem.notiObj.startTs);
        navigate(
          PAGE_PATH.MY_APPOINTMENT +
            `?date=${date}` +
            `#${notiItem?.notiObj?.meetingId}`
        );
        break;
      case NOTIFICATION_TYPE.TA_CREATE_ACCOUNT:
        const taId = notiItem?.notiObj.taId;
        // console.log(taId);
        navigate(PAGE_PATH.TA_MANAGEMENT + `#${taId}`);
        setShowNotification(!showNotification);
        break;
      case NOTIFICATION_TYPE.EXCHANGE_FROM_BONUS_TO_MONEY:
      case NOTIFICATION_TYPE.ADDING_MONEY_SUCCESS:
      case NOTIFICATION_TYPE.PAID_MEETING:
      case NOTIFICATION_TYPE.REFUND_MEETING:
      case NOTIFICATION_TYPE.WITHDRAW:
        // console.log(notiItem);
        const transId = notiItem?.notiObj.transId;
        navigate(PAGE_PATH.ORDER_RESULTS(transId));
        setShowNotification(!showNotification);
        break;
      case NOTIFICATION_TYPE.REQUEST_PUBLISH_DRAFT:
      case NOTIFICATION_TYPE.APPROVE_PUBLISH_DRAFT:
      case NOTIFICATION_TYPE.REJECT_PUBLISH_DRAFT:
        const courseId = notiItem?.notiObj?.courseId;
        navigate(PAGE_PATH.COURSE_MANAGEMENT_DASHBOARD(courseId));
        break;
      default:
        break;
    }
  };
  const data = [
    {
      type: NOTIFICATION_TYPE.TA_CREATE_ACCOUNT,
      email: "dodinhvan3111@gmail.com",
      name: "Đỗ Đình Văn",
      avt: "",
      time: "",
    },
    {
      type: NOTIFICATION_TYPE.TA_CREATE_ACCOUNT,
      email: "dodinhvan3111@gmail.com",
      name: "Đỗ Đình Văn",
      avt: "",
      time: "",
    },
    {
      type: NOTIFICATION_TYPE.SUCCESSFUL_BOOKED_A_MEETING,
      studentEmail: "dodinhvan3111@gmail.com",
      studentName: "Đỗ Đình Văn",
      studentAvt: "",
      taName: "Robert Lewandowski",
      taEmail: "barcelonano1@gmail.com",
      taAvt: "",
      time: "",
      scheduleTime: "Thứ 2, 1:00 PM",
    },
    {
      type: NOTIFICATION_TYPE.TA_CREATE_ACCOUNT,
      email: "dodinhvan3111@gmail.com",
      name: "Đỗ Đình Văn",
      avt: "",
      time: "",
    },
    {
      type: NOTIFICATION_TYPE.SUCCESSFUL_BOOKED_A_MEETING,
      studentEmail: "dodinhvan3111@gmail.com",
      studentName: "Đỗ Đình Văn",
      studentAvt: "",
      taName: "Robert Lewandowski",
      taEmail: "barcelonano1@gmail.com",
      taAvt: "",
      time: "",
      scheduleTime: "Thứ 2, 1:00 PM",
    },
  ];
  const fetchListAllNoti = async (page) => {
    setIsLoading(true);
    const res = await getListNoti(page, NOTI_PER_PAGE, null, false);
    setNotiList(res?.data?.docs);
    setHasMore(res?.data?.hasNextPage);
    setCurrentPage(page);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchListAllNoti(1);
  }, []);

  useEffect(() => {
    if (!notiList || notiList?.length === 0) {
      setNUnreadNoti(0);
      return;
    }
    const listUnread = notiList?.filter((item, index) => {
      if (index > LIMIT_INDEX_COUNT_UNREAD) return false;
      else return item?.isUnread === true;
    });
    setNUnreadNoti(listUnread.length);
  }, [notiList]);
  return (
    <Tooltip title="Thông báo" open={!childOpen && parentOpen}>
      <div
        ref={notiDropdownRef}
        className="relative"
        onMouseEnter={() => setParentOpen(true)}
        onMouseLeave={() => setParentOpen(false)}
      >
        <div
          ref={notiRef}
          className="main-notification relative cursor-pointer w-7 h-7 sm:w-9 sm:h-9"
          onClick={handleOpenNotification}
        >
          {nUnreadNoti > 0 && (
            <div
              className={`noti-number absolute w-4 sm:p-2 flex justify-center items-center ${
                nUnreadNoti > 10
                  ? "-top-2 -right-2"
                  : "top-0 -right-2 sm:right-0"
              } rounded-full h-5 sm:w-fit text-[10px] text-white bg-red-600`}
            >
              <strong>{nUnreadNoti > 10 ? "10+" : nUnreadNoti}</strong>
            </div>
          )}
          <NotificationsIcon
            fontSize="large"
            sx={{
              color: "rgb(14 165 233)",
              ":hover": { color: "rgb(2 132 199)" },
            }}
          ></NotificationsIcon>
        </div>
        {showNotification && (
          <div
            onMouseEnter={() => setChildOpen(true)}
            onMouseLeave={() => setChildOpen(false)}
          >
            <NotificationDropdown
              notiList={notiList}
              coords={notiCoords}
              isLoading={isLoading}
              isLoadingMore={isLoadingMore}
              hasMore={hasMore}
              notiPerPage={NOTI_PER_PAGE}
              handleLoadMoreNoti={handleLoadMoreNoti}
              handleMarkAllAsRead={handleMarkAllAsRead}
              handleOnClickNotiDropdownItem={handleOnClickNotiDropdownItem}
            ></NotificationDropdown>
          </div>
        )}
      </div>
    </Tooltip>
  );
};
export const CustomToastWithLink = (item) => {
  const renderNoti = (item) => {
    switch (item.type) {
      case NOTIFICATION_TYPE.TA_CREATE_ACCOUNT:
        return (
          <>
            <div className="flex gap-4 justify-center items-center">
              <Avatar src={item?.notiObj?.avt}></Avatar>
              <div className="text-sm">
                <h1 className="line-clamp-2">
                  Địa chỉ email
                  <span className="font-semibold">
                    {" "}
                    {item?.notiObj?.taEmail}{" "}
                  </span>
                  đã đăng ký tài khoản trợ giảng thành công với tên
                  <span className="font-semibold"> {item?.notiObj?.name}</span>
                </h1>
                <h1 className="mt-2 text-sm text-gray-500">
                  {getDateDiff(item?.ts)}
                </h1>
              </div>
            </div>
            {/* <PersonAddIcon color="success" fontSize="medium"></PersonAddIcon> */}
          </>
        );
      case NOTIFICATION_TYPE.SUCCESSFUL_BOOKED_A_MEETING:
        return (
          <>
            <div className="flex gap-4 justify-center items-center">
              <Avatar src={item?.notiObj?.avt}></Avatar>
              <div className="text-sm">
                <h1 className="line-clamp-2">
                  {item?.notiObj?.isStudent ? (
                    <>
                      Học viên
                      <span className="font-semibold">
                        {" "}
                        {item?.notiObj?.name}{" "}
                      </span>
                      đã đặt lịch hẹn thành công với bạn. Cuộc hẹn sẽ bắt đầu
                      vào lúc
                      <span className="font-semibold">
                        {" "}
                        {tsToDate(item?.notiObj?.startTs, true, true)}{" "}
                      </span>
                    </>
                  ) : (
                    <>
                      Bạn đã đặt lịch hẹn thành công với{" "}
                      <span className="font-semibold">
                        {" "}
                        {item?.notiObj?.name}{" "}
                      </span>
                      . Cuộc hẹn sẽ bắt đầu vào lúc
                      <span className="font-semibold">
                        {" "}
                        {tsToDate(item?.notiObj?.startTs, true, true)}{" "}
                      </span>
                    </>
                  )}
                </h1>
                <h1 className="mt-2 text-xs text-gray-500">
                  {getDateDiff(item?.ts)}
                </h1>
              </div>
            </div>
            {/* <EventAvailableIcon
              color="success"
              fontSize="medium"
            ></EventAvailableIcon> */}
          </>
        );
      case NOTIFICATION_TYPE.CANCEL_MEETING:
        return (
          <>
            <div className="flex gap-4 justify-center items-center">
              <Avatar src={item?.notiObj?.avt}></Avatar>
              <div className="text-sm">
                <h1 className="line-clamp-2">Một cuộc hẹn đã bị huỷ</h1>
                <h1 className="mt-2 text-xs text-gray-500">
                  {getDateDiff(item?.ts)}
                </h1>
              </div>
              <EventBusyIcon color="error" fontSize="medium"></EventBusyIcon>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.WITHDRAW:
        return (
          <>
            <div className="flex gap-4 justify-between items-center">
              <div className="flex gap-4 items-center max-w-[200px]">
                <WalletIcon className=""></WalletIcon>
                {/* <Avatar src={item?.notiObj?.avt}></Avatar> */}
                <div className="text-sm">
                  <h1 className="line-clamp-2">
                    Bạn đã rút tiền thành công thông qua Admin
                  </h1>
                  <h1 className="mt-2 text-xs text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <h1 className="flex-none text-sm font-semibold ">
                -{new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}đ
              </h1>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.EXCHANGE_FROM_BONUS_TO_MONEY:
        return (
          <>
            <div className="flex gap-4 justify-between items-center">
              <div className="flex gap-4 items-center max-w-[200px]">
                <RedeemIcon></RedeemIcon>
                {/* <Avatar src={item?.notiObj?.avt}></Avatar> */}
                <div className="text-sm">
                  <h1 className="line-clamp-2">Đổi điểm thành công</h1>
                  <h1 className="mt-2 text-xs text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <div className="text-end">
                <h1 className="flex-none text-sm text-green-500 font-semibold ">
                  +
                  {new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}
                  đ
                </h1>
                <h1 className="flex-none text-sm font-semibold ">
                  -{item?.notiObj?.points} điểm
                  {/* <RedeemIcon sx={{ fontSize: "18px" }}></RedeemIcon> */}
                </h1>
              </div>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.ADDING_MONEY_SUCCESS:
        return (
          <>
            <div className="flex gap-4 justify-between items-center">
              <div className="flex gap-4 items-center max-w-[200px]">
                <WalletIcon className="text-green-500"></WalletIcon>
                {/* <Avatar src={item?.notiObj?.avt}></Avatar> */}
                <div className="text-sm">
                  <h1 className="line-clamp-2">Nạp tiền thành công</h1>
                  <h1 className="mt-2 text-xs text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <h1 className="flex-none text-sm font-semibold text-green-500">
                +{new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}đ
              </h1>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.PAID_MEETING:
        return (
          <>
            <div className="flex gap-4 justify-between items-center">
              <div className="flex gap-4 items-center max-w-[200px]">
                <WalletIcon className=""></WalletIcon>
                {/* <Avatar src={item?.notiObj?.avt}></Avatar> */}
                <div className="text-sm">
                  <h1 className="line-clamp-2">Thanh toán thành công</h1>
                  <h1 className="mt-2 text-xs text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <h1 className="flex-none text-sm font-semibold ">
                -{new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}đ
              </h1>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.REFUND_MEETING:
        return (
          <>
            <div className="flex gap-4 justify-between items-center">
              <div className="flex gap-4 items-center max-w-[200px]">
                <CurrencyExchange className="text-green-500"></CurrencyExchange>
                {/* <Avatar src={item?.notiObj?.avt}></Avatar> */}
                <div className="text-sm">
                  <h1 className="line-clamp-2">Hoàn tiền thành công</h1>
                  <h1 className="mt-2 text-xs text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
              <h1 className="flex-none text-sm font-semibold text-green-500">
                +{new Intl.NumberFormat("vi-VN").format(item?.notiObj?.amount)}đ
              </h1>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.REQUEST_PUBLISH_DRAFT:
        return (
          <>
            <div className="flex gap-4 justify-center items-center">
              <Avatar src={item?.notiObj?.avt}></Avatar>
              <div className="text-sm">
                <h1 className="line-clamp-2">
                  Trợ giảng
                  <span className="font-semibold"> {item?.notiObj?.name} </span>
                  vừa gửi yêu cầu phê duyệt thay đổi thông tin bài học trong
                  khoá học <span>{item?.notiObj?.courseName}</span>
                </h1>
                <h1 className="mt-2 text-sm text-gray-500">
                  {getDateDiff(item?.ts)}
                </h1>
              </div>
            </div>
            {/* <EditIcon fontSize="medium"></EditIcon> */}
          </>
        );
      case NOTIFICATION_TYPE.APPROVE_PUBLISH_DRAFT:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Avatar src={item?.notiObj?.avt}></Avatar>
                <div className="text-sm max-w-[370px]">
                  <h1 className="line-clamp-3">
                    Quản trị viên
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.name}{" "}
                    </span>
                    vừa phê duyệt một thay đổi ở khoá học
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.courseName}
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
            </div>
          </>
        );
      case NOTIFICATION_TYPE.REJECT_PUBLISH_DRAFT:
        return (
          <>
            <div className="flex gap-4 justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Avatar src={item?.notiObj?.avt}></Avatar>
                <div className="text-sm max-w-[370px]">
                  <h1 className="line-clamp-3">
                    Quản trị viên
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.name}{" "}
                    </span>
                    vừa từ chối một thay đổi ở khoá học
                    <span className="font-semibold">
                      {" "}
                      {item?.notiObj?.courseName}
                    </span>
                  </h1>
                  <h1 className="mt-2 text-sm text-gray-500">
                    {getDateDiff(item?.ts)}
                  </h1>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <div></div>;
    }
  };
  return <div>{renderNoti(item)}</div>;
};
