import { createSlice } from "@reduxjs/toolkit";

// Structure
// data: {
//   id: null,
//   email: null,
//   name: null,
//   addr: null,
//   picture: null,
//   role: null,
//   balance: null,
//   bonus: null,
// },
// token: {
//   accessToken: null,
//   refreshToken: null
// },
// provider: null,
// status: null,
// curFCMToken: {
//   ts: Number,
//   token: String
// }
// tokenExpr: false

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
  },
  reducers: {
    login: (state, { payload }) => {
      // console.log("🚀 ~ file: authSlice.jsx:107 ~ payload:", payload);
      return {
        // Set user info
        ...state,
        user: {
          data: {
            ...state.user?.data,
            id: payload.id,
            email: payload.email,
            name: payload.name,
            addr: payload.addr || null,
            picture: payload.avt || null,
            role: payload.role,
            balance: payload.balance,
          },
          token: {
            accessToken: payload.accessToken,
            refreshToken: payload.refreshToken,
          },
          provider: payload.provider || null,
          status: payload.status,
          curFCMToken: {
            inUse: false,
            ts: 0,
            token: null,
          },
          tokenExpr: false,
        },
      };
    },
    logout: (state) => ({
      ...state,
      user: {},
    }),
    update: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        data: {
          ...state.user.data,
          addr: payload.addr,
          name: payload.name,
          picture: payload.avt || payload.picture || null,
          role: payload.role,
          balance: payload.balance,
        },
        provider: payload.provider || null,
        curFCMToken: payload.curFCMToken || state.user.curFCMToken,
      },
    }),
    invalidateTok: (state) => ({
      ...state,
      user: {
        ...state.user,
        tokenExpr: true,
      },
    }),
    updateCurFCMToken: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        curFCMToken: payload,
      },
    }),
    updateBalance: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        data: {
          ...state.user.data,
          balance: payload.balance,
        },
      },
    }),
    updateBonus: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        data: {
          ...state.user.data,
          bonus: payload.bonus,
        },
      },
    }),
    updateProfileInfo: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        data: {
          ...state.user.data,
          name: payload.name || state.user.data.name,
          addr: payload.addr || state.user.data.addr,
        },
      },
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  logout,
  update,
  invalidateTok,
  updateCurFCMToken,
  updateBalance,
  updateBonus,
  updateProfileInfo,
} = authSlice.actions;

export default authSlice.reducer;
