export const API = {
  LOGOUT: "/api/authen/logout",
  LOGIN: "/api/authen/login",
  LOGIN_GOOGLE: "/api/authen/google",
  REGISTER: "/api/authen/register",
  VERIFY_ACCOUNT_BY_TOKEN: "/api/user/verify-status/verify",

  // Meeting
  CREATE_MEETING: "/api/meeting/create",
  CANCEL_MEETING: "/api/meeting/cancelled",
  JOIN_MEETING: "/api/meeting/join",
  EXTEND_MEETING: "/api/meeting/extend-time",
  RATE_MEETING: "/api/rating/meeting/leave-comment",

  FORGOT_PASSWORD: "/api/user/reset-password/send-reset-email",
  CHECK_RESET_PASSWORD_TOKEN: "/api/user/reset-password/check-valid",
  RESET_PASSWORD_BY_TOKEN: "/api/user/reset-password/update-by-token",

  // Profile
  UPDATE_AVATAR: "/api/profile/avatar/update",
  REMOVE_AVATAR: "/api/profile/avatar/remove",
  GET_PROFILE_INFO: "/api/user/profile",
  GET_BONUS_POINTS: "/api/user/my-bonus-points",
  UPDATE_PASSWORD: "/api/user/password",
  UPDATE_PROFILE: "/api/user/profile",

  // Upload
  UPLOAD_INIT: "api/upload/start-upload",
  UPLOAD_PART: "api/upload/part-upload",
  UPLOAD_COMPLETE: "api/upload/complete-upload",
  REMOVE_RESOURCE_PERMANENT: "/api/resource/remove",

  // Payment
  CREATE_ORDER: "/api/payment/create_payment_url",
  UPDATE_ORDER: "/api/payment/payment_update",
  RECEIVE_ORDER: "/api/payment/return_payment_results",
  WITHDRAW_ORDER: "/api/withdraw/withdraw-via-admin",
  EXCHANGE_ORDER: "/api/bonus/exchange-points",

  // Transaction
  LIST_TRANSACTION: (isAsc, page, limit, filterTypes, fromTs, endTs) =>
    `/api/transaction/list?isAsc=${isAsc}&page=${page}&limit=${limit}&filterTypes=${filterTypes}&fromTs=${fromTs}&endTs=${endTs}`,
  TRANSACTION_DETAIL: (transId) => `/api/transaction/detail?transId=${transId}`,

  // Topic
  GET_LIST_TOPIC: (courseId) => {
    return `/api/course/${courseId}/topic`;
  },
  CREATE_TOPIC: (courseId) => {
    return `/api/admin/create-topic/${courseId}`;
  },
  UPDATE_TOPIC: (topicId) => `/api/topic/update/${topicId}`,
  DELETE_TOPIC: (topicId) => `/api/topic/delete/${topicId}`,
  LIST_DRAFT_BY_TOPIC: (topicId, sorting) =>
    `/api/lesson/draft-list-by-topic/${topicId}?sorting=${
      sorting && JSON.stringify(sorting)
    }`,

  // Lesson
  ADD_RESOURCE: "/api/lesson/add-resource",
  REMOVE_RESOURCE: "/api/lesson/remove-resource",
  GET_LIST_RESOURCE: (lessonId) => {
    return `/api/lesson/${lessonId}/list-resource`;
  },
  GET_LIST_LESSON: (topicId) => {
    return `/api/lesson/list-by-topic/${topicId}`;
  },
  GET_LESSON_DETAIL: (lessonId) => {
    return `/api/lesson/detail/${lessonId}`;
  },
  CREATE_LESSON: (courseId) => `/api/admin/create-lesson/${courseId}`,
  COMPLETE_LESSON: (lessonId) => `/api/lesson/complete-lesson/${lessonId}`,
  UPDATE_LESSON: (lessonId) => `/api/lesson/update/${lessonId}`,
  DELETE_LESSON: (lessonId) => `/api/lesson/delete/${lessonId}`,
  CHANGE_EXERCISES_ORDER: "/api/lesson/change-exercises-order",
  CHANGE_LESSON_ORDER_IN_TOPIC: (topicId) => `/api/lesson/sort/${topicId}`,
  CHANGE_TOPIC_ORDER_IN_COURSE: (courseId) => `/api/topic/sort/${courseId}`,
  UPDATE_LESSON_DRAFT: `/api/lesson-draft/update`,
  ADMIN_REJECT_DRAFT: `/api/draft/reject`,
  ADMIN_APPROVE_DRAFT: `/api/draft/approve`,
  // Course
  COURSE_DETAIL: "/api/course",
  RECOMMEND_COURSES: "/api/course/recommend-courses",
  CREATE_COURSE: "/api/course/create",
  ASSIGN_TA_FOR_COURSE: (id) => `/api/admin/assign-ta-for-course/${id}`,
  GET_ALL_COURSE: (page, itemPerPage) =>
    `/api/course/list?page=${page}&limit=${itemPerPage}`,
  COURSES_OF_STUDENT: "/api/course/mine",
  REGISTRY_COURSES: "/api/course/registry",
  SEARCH_COURSE_BY_KEY: (
    key,
    page,
    itemPerPage,
    listTAIds,
    fromRating,
    toRating,
    sorting
  ) =>
    `/api/course/list?page=${page}&limit=${itemPerPage}&search=${key}&ta=${listTAIds}&sorting=${
      sorting && JSON.stringify(sorting)
    }&fromStar=${fromRating}&toStar=${toRating}`,
  COURSE_STATISTIC: (
    page,
    limit,
    courseId,
    uids = "",
    isOnlyEnrolled = 1,
    isGetTotalExer = 1
  ) =>
    `/api/admin/activities/users-activities?page=${page}&limit=${limit}&courseId=${courseId}&uids=${uids}&isOnlyEnrolled=${isOnlyEnrolled}&isGetTotalExer=${isGetTotalExer}`,
  UPDATE_COURSE_INFO: (id) => `/api/course/update/${id}`,
  UPDATE_TA_FOR_COURSE: (id) => `/api/admin/update-tas-of-course/${id}`,
  HIDE_COURSE: "/api/course/change-visibility",
  REQUEST_PUBLISH_DRAFT: "/api/draft/request-publish",
  GET_LIST_UNPUBLISH_COURSE: (page, limit) =>
    `/api/draft/unpublish-course-list?page=${page}&limit=${limit}`,
  // Exercise
  GET_LESSON_EXERCISES: (
    lessonId,
    isGetTemplateLangIds,
    isGetQuizCompletedStatus
  ) =>
    `/api/exercise/entire-list?lessonId=${lessonId}&isGetTemplateLangIds=${isGetTemplateLangIds}&isGetQuizCompletedStatus=${isGetQuizCompletedStatus}`,
  ADD_EXERCISES_LESSON: "/api/lesson/add-exercise",
  CREATE_QUIZ: "/api/exercise/quiz/create",
  REMOVE_EXERCISE: "/api/lesson/remove-exercise",
  CREATE_CODING: "/api/exercise/coding/create",
  SUBMIT_EXERCISE: "/api/exercise/quiz/submit-ans",
  UPDATE_QUESTION_EXERCISE: "/api/exercise/update-ques",
  ADD_ANS_QUIZ_EXERCISE: "/api/exercise/quiz/add-ans",
  REMOVE_ANS_QUIZ_EXERCISE: "/api/exercise/quiz/rm-ans",
  UPDATE_TRUE_ANS_EXERCISE: "/api/exercise/quiz/update-true-ans",
  UPDATE_ANS_DESCRIPTION_EXERCISE: "/api/exercise/quiz/edit-ans",
  UPDATE_CODING_EXERCISE: "/api/exercise/coding/update",

  // Test case
  CREATE_TEST_CASE: "/api/test-case/add",
  GET_HIDDEN_TEST_CASES: (exerciseId) =>
    `/api/test-case/entire-hidden-list?exerciseId=${exerciseId}`,
  UPDATE_TEST_CASE: "/api/test-case/update",

  //note
  CREATE_NOTE: (id) => {
    return `/api/lesson/${id}/create-note`;
  },
  LIST_NOTE: (id) => {
    return `/api/lesson/${id}/list-note`;
  },
  LIST_NOTE_OF_COURSE: (
    page,
    limit,
    courseId,
    topicIdList,
    lessonIdList,
    isAsc = 0
  ) =>
    `/api/note/notes-list?courseId=${courseId}${
      lessonIdList !== "" ? `&lessonIdList=${lessonIdList}` : ""
    }${
      topicIdList !== "" ? `&topicIdList=${topicIdList}` : ""
    }&page=${page}&limit=${limit}&isAsc=${isAsc}`,
  DELETE_NOTE: (id) => {
    return `/api/lesson/${id}/delete-note`;
  },
  UPDATE_NOTE: (id) => {
    return `/api/lesson/${id}/update-note`;
  },

  // Run code
  RUN_CODE: "/api/exercise/coding/run",
  // Submit code
  SUBMIT_CODE: "/api/exercise/submit/coding/scoring",
  LAST_RUN_CODE: (lessonId, exerciseId, isShortInfo, isWait) =>
    `/api/exercise/coding/last-run-code?lessonId=${lessonId}&exerciseId=${exerciseId}&isShortInfo=${isShortInfo}&isWait=${isWait}`,
  GET_SUBMIT_HISTORY: (lessonId, exerciseId, page, limit, isDesc) => {
    return `/api/exercise/history/coding?lessonId=${lessonId}&exerciseId=${exerciseId}&page=${page}&limit=${limit}&isDesc=${isDesc}`;
  },
  GET_SUBMIT_INFO: (submissionId) => {
    return `/api/exercise/history/coding-submission-info?submissionId=${submissionId}`;
  },
  SAVE_CODE: `/api/exercise/coding/save`,

  // Template
  CREATE_TEMPLATE: "/api/exercise/template/coding/create",
  GET_TEMPLATE: (lessonId, exerciseId, langId) =>
    `/api/exercise/template/coding/info?lessonId=${lessonId}&exerciseId=${exerciseId}&langId=${langId}`,
  DELETE_TEMPLATE: "/api/exercise/template/coding/remove",

  // TA
  SEARCH_TA: (key, page, limit) => {
    return `/api/util/ta?search=${key}&page=${page}&limit=${limit}`;
  },
  INVITE_NEW_TA: "/api/admin/manage-teaching-assistant/create",
  CHECK_INVITE_TA_TOKEN: "/api/admin/manage-teaching-assistant/check-valid",
  REGISTER_TA_BY_TOKEN:
    "/api/admin/manage-teaching-assistant/register-by-token",

  // Comment
  CREATE_COMMENT: (idComment) => `/api/user-lesson-comments/${idComment}`,
  EDIT_COMMENT: (idComment) => `/api/user-lesson-comments/${idComment}`,
  DELETE_COMMENT: (idComment) => `/api/user-lesson-comments/${idComment}`,
  GET_LIST_COMMENT_OF_LESSON: (idComment, page, limit, sorting) =>
    `/api/user-lesson-comments/${idComment}?page=${page}&limit=${limit}&sorting=${
      sorting && JSON.stringify(sorting)
    }`,
  CREATE_REPLY_COMMENT_OF_COMMENT: (idComment) =>
    `/api/user-lesson-comments/reply-comment/${idComment}`,
  GET_LIST_REPLY_OF_COMMENT: (idComment, page, limit) =>
    `/api/user-lesson-comments/reply-comment/${idComment}?page=${page}&limit=${limit}`,
  LIKE_COMMENT: (commentId) => `/api/user-lesson-comments/like/${commentId}`,
  UNLIKE_COMMENT: (commentId) =>
    `/api/user-lesson-comments/unlike/${commentId}`,

  //appointment
  GET_WORKING_SHIFTS: (page, limit) =>
    `/api/working-shift/list?limit=${limit}&page=${page}`,
  GET_FREE_SHIFTS: (dateStrArr, page, limit) =>
    `/api/management/working-shift/my-free-shift?dateStrArr=${dateStrArr}&page=${page}&limit=${limit}`,
  CREATE_FREE_SHIFT: "/api/management/working-shift/create",
  CANCEL_FREE_SHIFT: "/api/management/working-shift/cancel",
  SEARCH_TA_BY_WORKING_SHIFT: (courseId, dateStr, shiftIdList) =>
    `/api/working-shift/search-ta?courseId=${courseId}&dateStr=${dateStr}&shiftIdList=${shiftIdList}`,
  GET_MY_APPOINTMENT: (page, limit, dateStr, getRating = 0) =>
    `/api/management/meeting/my-appointment?page=${page}&limit=${limit}&dateStrList=${dateStr}&getRating=${getRating}`,

  // Notification
  SEND_NOTI_FOR: (uId, type) =>
    `/api/util/test/websocket?uid=${uId}&type=${type}`,
  GET_LIST_NOTI: (page, limit, isOnlyUnread, isAsc) =>
    `/api/notification/list?page=${page}&limit=${limit}&isAsc=${
      isAsc ? "1" : "0"
    }${isOnlyUnread ? `&isOnlyUnread=${isOnlyUnread ? "1" : "0"}` : ""}`,
  MARK_AS_READ: "/api/notification/mark-as-read",
  MARK_AS_UNREAD: "/api/notification/mark-as-unread",
  UPDATE_NOTI_TOKEN: "/api/notification/update-token",

  // Rating
  VOTE_TA: (taId) => `/api/student/rating-ta/${taId}`,
  DETAIL_VOTE_TA: (userId, taId, courseId) =>
    `/api/student/rating-ta/detail?userId=${userId}&courseId=${courseId}&taId=${taId}`,
  UPDATE_VOTE_TA: (voteId) => `/api/student/rating-ta/${voteId}`,
  DELETE_VOTE_TA: (voteId) => `/api/student/rating-ta/${voteId}`,
  VOTE_COURSE: (courseId) => `/api/student/rating-course/${courseId}`,
  DETAIL_VOTE_COURSE: (userId, courseId) =>
    `/api/student/rating-course/detail?userId=${userId}&courseId=${courseId}`,
  UPDATE_VOTE_COURSE: (voteId) => `/api/student/rating-course/${voteId}`,
  DELETE_VOTE_COURSE: (voteId) => `/api/student/rating-course/${voteId}`,
  LIST_REVIEW_COURSE: (courseId, page, limit, sort) =>
    `/api/student/list-rating-course/${courseId}?page=${page}&limit=${limit}&sorting=${sort}`,
  LIST_RATING_TA: (taId, page, limit, sort) =>
    `/api/student/list-rating-ta/${taId}?page=${page}&limit=${limit}&sorting=${sort}`,
  LIST_STUDENT_REVIEW_FOR_COURSE: (studentId, page, limit, sort) =>
    `/api/student/${studentId}/list-rating-course?page=${page}&limit=${limit}0&sorting=${sort}`,
  LIST_STUDENT_RATING_FOR_TA: (studentId, page, limit, sort) =>
    `/api/student/${studentId}/list-rating-ta?page=${page}&limit=${limit}&sorting=${sort}`,
  // View user profile
  VIEW_USER_PROFILE: (id) => `/api/util/user-info/${id}`,

  // Realtime lang coding
  CHANGLE_LANG_REALTIME: "/api/collab-coding/change-lang",

  // Student
  SEARCH_STUDENTS: (key, page, limit) =>
    `/api/util/student?search=${key}&page=${page}&limit=${limit}`,

  // Report comment
  CREATE_REPORT: (commentId) => `/api/report/create/${commentId}`,
  GET_LIST_REPORT: (page, limit) =>
    `/api/report/list?page=${page}&limit=${limit}`,
  JUMP_TO_COMMENT: (commentId, lessonId) =>
    `/api/user-lesson-comments/jump-to?id=${commentId}&lessonId=${lessonId}`,

  // Draft
  LIST_DRAFT_TOPIC: (
    topicId
  ) => `/api/lesson/draft-list-by-topic/${topicId}?sorting={"index":"-1"}
`,
};

export const WS_API = {
  COLAB_CODE: "/ws/api/colab-code",
  REALTIME_API: "/ws/api/real-time",
};

export const WS_EVENT = {
  NOTIFICATION_EVENT: "1",
  SYNC_LANG: "2",
};
