const RESP = {
  SUCCESS: 0,
  INVALID_DATA: 1,
  INTERNAL_ERR: 2,
  NOT_FOUND: 3,

  // Login
  WRONG_PASS: -3,
  EMAIL_ALREADY_EXISTS: -1,
  EMAIL_ALREADY_LINKED: -2,

  // Forgot pass
  EMAIL_CREATED_3RD_PARTY: 8,

  // Meeting
  EXISTED: 4,
  TIMEOUT_RATING: 7,

  RETRY_LATER: 5,
  PAYMENT_FAILED_CHECKSUM: 97,
  ALREADY_EXISTS: 6,
  NOT_ENOUGH: 10,
  VNPAY_ERR: 11,
  NOT_ENOUGH_POINTS: 13,

  JITSI_EXPIRED_ROOM: 100,
  JITSI_TOO_EARLY: 101,
};

export const VNP_RESP = {
  "00": "Giao dịch thành công",
  "07": "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).",
  "09": "Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.",
  10: "Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần.",
  11: "Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.",
  12: "Thẻ/Tài khoản của khách hàng bị khóa.",
  13: "Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.",
  24: "Khách hàng hủy giao dịch.",
  51: "Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.",
  65: "Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.",
  75: "Ngân hàng thanh toán đang bảo trì.",
  79: "Nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch.",
  99: "Giao dịch không thành công",
};

export const UPLOAD_RESP = {
  FAILED: -1,
  SUCCESS: 0,
};

export default RESP;
