import { API } from "../common/api";
import { handleGet, handlePost } from "./fetch";

export const updateAvatar = (avatarFile) => {
  var data = new FormData();
  data.append("img", avatarFile, avatarFile.name);
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.status === 500) {
          resolve({ status: 500, message: "File too large" });
        }
        resolve(JSON.parse(this.responseText));
      }
    });

    xhr.open("POST", API.UPDATE_AVATAR);

    xhr.send(data);
  });
};

export const removeAvatar = async () => {
  try {
    const res = await handlePost(API.REMOVE_AVATAR);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getProfileInfo = async () => {
  try {
    const res = await handleGet(API.GET_PROFILE_INFO);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getUserProfile = async (id) => {
  try {
    const res = await handleGet(API.VIEW_USER_PROFILE(id));
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getUserBonus = async () => {
  try {
    const res = await handleGet(API.GET_BONUS_POINTS);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updatePassword = async (curPwd, pwd) => {
  const data = {
    curPwd,
    pwd,
  };
  try {
    const res = await handlePost(API.UPDATE_PASSWORD, data);
    return res;
  } catch (error) {
    console.log(
      "🚀 ~ file: profileHelper.js:71 ~ updatePassword ~ error:",
      error
    );
  }
};

export const updateProfile = async (addr, name) => {
  const data = {};

  if (addr) {
    data.addr = addr;
  }

  if (name) {
    data.name = name;
  }

  try {
    const res = await handlePost(API.UPDATE_PROFILE, data);
    return res;
  } catch (error) {
    console.log(
      "🚀 ~ file: profileHelper.js:93 ~ updateProfile ~ error:",
      error
    );
  }
};
