export const SUBMIT_STATUS = {
  ERROR: "error",
  SUCCESS: "success",
  LOADING: "loading",
};

export const RESOURCE_TYPE = {
  VIDEO: 1,
  DOCUMENT: 2,
  AUDIO: 3,
  THUMB_COURSE: 4,
};

// Upload status
export const UPLOAD_STATUS = {
  FAILED: "upload_failed",
  SUCCESS: "upload_success",
};

// Exercise
export const EXERCISE_TYPE = {
  MULTI_CHOICE: 1,
  CODING: 2,
};

// Compiler
export const LANG_ID = {
  ASSEMBLY: 45,
  BASH: 46,
  C: 50,
  C_PLUS_PLUS: 54,
  C_SHARP: 51,
  ELIXIR: 57,
  GO: 60,
  JAVA: 62,
  NODEJS: 63,
  KOTLIN: 78,
  OBJECTIVE_C: 79,
  PASCAL: 67,
  PERL: 85,
  PHP: 68,
  PYTHON: 71,
  R: 80,
  RUBY: 72,
  RUST: 73,
  SCALA: 81,
  SWIFT: 83,
  TYPESCRIPT: 74,
  MULTI_FILE: 89,
};

export const LANG_ID_TO_TEXT = {
  45: "Assembly",
  46: "Bash",
  50: "C",
  54: "C++",
  51: "C#",
  57: "Elixir",
  60: "Go",
  62: "Java",
  63: "Javascripts",
  78: "Kotlin",
  79: "Objective-C",
  67: "Pascal",
  85: "Perl",
  68: "PhP",
  71: "Python",
  80: "R",
  72: "Ruby",
  73: "Rust",
  81: "Scala",
  83: "Swift",
  74: "Typescripts",
  89: "Multi_File",
};

export const LANG_LIST = [
  {
    name: "C",
    value: LANG_ID.C,
  },
  {
    name: "C++",
    value: LANG_ID.C_PLUS_PLUS,
  },
  {
    name: "C#",
    value: LANG_ID.C_SHARP,
  },
  {
    name: "Go",
    value: LANG_ID.GO,
  },
  {
    name: "Java",
    value: LANG_ID.JAVA,
  },
  {
    name: "Javascripts",
    value: LANG_ID.NODEJS,
  },
  {
    name: "Python",
    value: LANG_ID.PYTHON,
  },
  {
    name: "Kotlin",
    value: LANG_ID.KOTLIN,
  },
  {
    name: "Objective-C",
    value: LANG_ID.OBJECTIVE_C,
  },
  {
    name: "Pascal",
    value: LANG_ID.PASCAL,
  },
  {
    name: "Perl",
    value: LANG_ID.PERL,
  },
  {
    name: "PhP",
    value: LANG_ID.PHP,
  },
  {
    name: "R",
    value: LANG_ID.R,
  },
  {
    name: "Ruby",
    value: LANG_ID.RUBY,
  },
  {
    name: "Rust",
    value: LANG_ID.RUST,
  },
  {
    name: "Swift",
    value: LANG_ID.SWIFT,
  },
  {
    name: "Typescripts",
    value: LANG_ID.TYPESCRIPT,
  },
];

export const JUDGE_STATUS = {
  CUSTOM_ERR_NOT_DONE: -1,
  IN_QUEUE: 1,
  PROCESSING: 2,
  ACCEPTED: 3,
  WRONG_ANS: 4,
  TIME_LIMIT_EXCEEDED: 5,
  COMPILATION_ERR: 6,
  RUNTIME_ERR_7: 7,
  RUNTIME_ERR_8: 8,
  RUNTIME_ERR_9: 9,
  RUNTIME_ERR_10: 10,
  RUNTIME_ERR_11: 11,
  RUNTIME_ERR_12: 12,
  INTERNAL_ERR: 13,
  EXEC_FORMAT_ERR: 14,
};

export const JUDE_ERROR_MSG = [
  {
    id: 1,
    description: "In Queue",
  },
  {
    id: 2,
    description: "Processing",
  },
  {
    id: 3,
    description: "Test case passed",
  },
  {
    id: 4,
    description: "Test case failed",
  },
  {
    id: 5,
    description: "Time Limit Exceeded",
  },
  {
    id: 6,
    description: "Compilation Error",
  },
  {
    id: 7,
    description: "Runtime Error (SIGSEGV)",
  },
  {
    id: 8,
    description: "Runtime Error (SIGXFSZ)",
  },
  {
    id: 9,
    description: "Runtime Error (SIGFPE)",
  },
  {
    id: 10,
    description: "Runtime Error (SIGABRT)",
  },
  {
    id: 11,
    description: "Runtime Error (NZEC)",
  },
  {
    id: 12,
    description: "Runtime Error (Other)",
  },
  {
    id: 13,
    description: "Internal Error",
  },
  {
    id: 14,
    description: "Exec Format Error",
  },
];

export const EXERCISE_CONTRAINTS = {
  wall_time_limit: 1, // in seconds
  memory_limit: 30 * 1024, // in kilobytes
  max_file_size: 1, // in kilobytes
  enable_network: false,
};

export const EXERCISE_MAX_CONSTRAINT = {
  wall_time_limit: 30, // in seconds
  memory_limit: 100 * 1024, // in kilobytes
  max_file_size: 5 * 1024, // in kilobytes
};

export const OUTPUT_TYPE = {
  CONSOLE: "console",
  FILE: "out_file",
};

export const RUN_CODE_TYPE = {
  RUN_CODE: 1,
  SUBMIT_CODE: 2,
};

//type of drawer
export const TYPE_OF_DRAWER = {
  LESSON_LIST: 1,
  NOTE_LIST: 2,
};

// All the hours in the day
export const times = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23,
];

// Notification type
export const NOTIFICATION_TYPE = {
  SUCCESSFUL_BOOKED_A_MEETING: 1,
  TA_CREATE_ACCOUNT: 2,
  ADDING_MONEY_SUCCESS: 3,
  CANCEL_MEETING: 4,
  WITHDRAW: 5,
  EXCHANGE_FROM_BONUS_TO_MONEY: 6,
  REQUEST_PUBLISH_DRAFT: 7,
  APPROVE_PUBLISH_DRAFT: 8,
  REJECT_PUBLISH_DRAFT: 9,
  PAID_MEETING: 100,
  REFUND_MEETING: -100,
};

//student course status
export const STUDENT_CODE_STATUS = {
  ALL: "all",
  LEARNING: "isLearning",
  DONE: "isDone",
  YET_START: "registered",
};

export const CREATE_MEETING_STATUS = {
  SUCCESS: 0,
  INVALID_DATA: 1,
  NOT_ENOUGH_BALANCE: 10,
  NOT_AVAILABLE: 4,
  ANOTHER_APPOINTMENT: 9,
  NOT_FOUND: 3,
};

export const CANCEL_MEETING_STATUS = {
  SUCCESS: 0,
  INVALID_DATA: 1,
  NOT_FOUND: 3,
  LIMIT_RATE: 5,
  RULES: 12,
};

export const CANCEL_FREESHIFTS_STATUS = {
  SUCCESS: 0,
  INVALID_DATA: 1,
  NOT_FOUND: 3,
  EXISTED: 4,
  EXCEEDED_TIME: 7,
};

// Order type
export const ORDER_TYPE = {
  ADD_BALANCE: 0,
  CHARGE_MEETING: 1,
  REFUND_MEETING: 2,
  WITHDRAW: 3,
};

export const PAYMENT_TYPE = {
  VNPAY: 1,
  BONUS: 2,
};

export const TRANS_OBJECT_TO_TEXT = {
  0: "Số dư",
  [PAYMENT_TYPE.VNPAY]: "VNPAY",
  [PAYMENT_TYPE.BONUS]: "Đổi điểm thưởng",
};

// Rate comment type
export const RATE_COMMENT_TYPE = {
  RATE_TA: 1,
  REVIEW_COURSE: 2,
  STUDENT_ACTIVITIES_RATE_TA: 3,
  STUDENT_ACTIVITIES_REVIEW_COURSE: 4,
};

export const thumbDefaultCourse = "/images/course/angular_course.png";

export const STUDY_STATUS = {
  ALL: 0, // this value not exist in the database

  NOT_STUDY: 1,

  LEARNING: 2,

  DONE: 3,
};

export const SORT_ATTR_MY_COURSE = {
  ASC: {
    ENROLLED_TIME: 1,

    N_LESSONS: 2,

    N_COMPLETED_LESSONS: 3,

    N_STUDENTS: 4,

    AVG_STARS: 5,

    CREATED_TIME: 6,
  },
  DESC: {
    ENROLLED_TIME: -1,

    N_LESSONS: -2,

    N_COMPLETED_LESSONS: -3,

    N_STUDENTS: -4,

    AVG_STARS: -5,

    CREATED_TIME: -6,
  },
};
// Rating filter
export const RATING_FILTER = {
  DEFAULT: "0",
  ONE_AND_UP: "1",
  TWO_AND_UP: "2",
  THREE_AND_UP: "3",
  FOUR_AND_UP: "4",
  FIVE_ONLY: "5",
};

export const SORT_COMMENT = {
  TIME_ASC: { _id: "-1" },
  TIME_DESC: { _id: "1" },
  MOST_LIKE: { likeCommentUids: "-1" },
};

export const LOGIN_PROVIDER = {
  GOOGLE: "google",
};
