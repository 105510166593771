import { lazy } from "react";
import LAYOUT_LIST from "../layout/layout-list";
import { PAGE_PATH } from "./page-paths";
import { ROLE } from "../utils/constant";

const DELAY_TIME = 500;

// ================================= Components =================================
// Authen
const SignupPage = lazy(() =>
  Promise.all([
    import("../pages/authen/RegisterPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const SignInPage = lazy(() =>
  Promise.all([
    import("../pages/authen/LoginPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const VerifyEmailPage = lazy(() =>
  Promise.all([
    import("../pages/authen/VerifyEmailPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const ResetPasswordPage = lazy(() =>
  Promise.all([
    import("../pages/authen/ResetPasswordPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Meeting
const MeetingPage = lazy(() =>
  Promise.all([
    import("../pages/meeting/MeetingPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const JoinMeetingPage = lazy(() =>
  Promise.all([
    import("../pages/meeting/JoinMeetingPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const RatingAfterMeetingPage = lazy(() =>
  Promise.all([
    import("../pages/meeting/RatingAfterMeetingPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Upload
const UploadResourcePage = lazy(() =>
  Promise.all([
    import("../pages/resource/UploadResourcePage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Payment
const OrderPage = lazy(() =>
  Promise.all([
    import("../pages/payment/OrderPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const OrderResultsPage = lazy(() =>
  Promise.all([
    import("../pages/payment/OrderResultsPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const OrderResultsFailedPage = lazy(() =>
  Promise.all([
    import("../pages/payment/OrderFailedPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const OrderResultsProcessPage = lazy(() =>
  Promise.all([
    import("../pages/payment/OrderResultsProcessPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Profile
const ProfilePage = lazy(() =>
  Promise.all([
    import("../pages/profile/ProfilePage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Course
const CourseDetailPage = lazy(() =>
  Promise.all([
    import("../pages/course/CourseDetailPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const MyCoursePage = lazy(() =>
  Promise.all([
    import("../pages/course/MyCoursePage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const CourseManagementPage = lazy(() =>
  Promise.all([
    import("../pages/course/CourseManagementPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const CourseStatisticPage = lazy(() =>
  Promise.all([
    import("../pages/course/managemet/CourseStatisticPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const CourseWorkingShiftsPage = lazy(() =>
  Promise.all([
    import("../pages/course/workingShifts/CourseWorkingShifts"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const SearchCourseResultPage = lazy(() =>
  Promise.all([
    import("../pages/course/SearchCourseResultPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
// Course management
const CourseManagementDashboard = lazy(() =>
  Promise.all([
    import("../pages/course/managemet/CourseManagementDashboard"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// TA
const TAManagementPage = lazy(() =>
  Promise.all([
    import("../pages/ta/TAManagementPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const TACoursesPage = lazy(() =>
  Promise.all([
    import("../pages/ta/TACoursesPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const RegisterTAPage = lazy(() =>
  Promise.all([
    import("../pages/ta/RegisterTAPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const GuideTAPage = lazy(() =>
  Promise.all([
    import("../pages/ta/GuideTAPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Student
const StudentManagementPage = lazy(() =>
  Promise.all([
    import("../pages/student/StudentManagementPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Learning
const CourseLearningPage = lazy(() =>
  Promise.all([
    import("../pages/learning/CourseLearningPage"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

//appointment
const TAManageShift = lazy(() =>
  Promise.all([
    import("../pages/appointment/TAManageShift"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const MyAppointment = lazy(() =>
  Promise.all([
    import("../pages/appointment/MyAppointment"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Misc
const HomePage = lazy(() =>
  Promise.all([
    import("../pages/HomePage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);
const NotFoundPage = lazy(() =>
  Promise.all([
    import("../pages/NotFoundPage.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// Comment report management
const CommentReportManagement = lazy(() =>
  Promise.all([
    import("../pages/comment-report/CommentReportManagement"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// note
const NoteManagementPage = lazy(() =>
  Promise.all([
    import("../pages/note/NoteManagement.jsx"),
    new Promise((resolve) => setTimeout(resolve, DELAY_TIME)),
  ]).then(([module]) => module)
);

// ================================= Routes =================================
// Constructor
function pageRoute(
  path,
  component,
  stopWhenLogin,
  noLayout,
  layout = LAYOUT_LIST.BASIC,
  role = [ROLE.ADMIN, ROLE.STUDENT, ROLE.TA]
) {
  this.path = path;
  this.component = component;
  this.stopWhenLogin = stopWhenLogin;
  this.noLayout = noLayout;
  this.layout = layout;
  this.role = role;
}

// Protected
const protectedRoutes = [
  // Auth
  new pageRoute(PAGE_PATH.LOGIN, SignInPage, true, true),
  new pageRoute(PAGE_PATH.REGISTER, SignupPage, true, true),
  new pageRoute(PAGE_PATH.VERIFY_EMAIL, VerifyEmailPage, true, true),

  // Meeting
  new pageRoute(
    PAGE_PATH.MEETING,
    MeetingPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.STUDENT, ROLE.TA]
  ),
  new pageRoute(
    PAGE_PATH.ROOM,
    JoinMeetingPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.STUDENT, ROLE.TA]
  ),
  new pageRoute(
    PAGE_PATH.RATE_MEETING,
    RatingAfterMeetingPage,
    false,
    true,
    LAYOUT_LIST.BASIC,
    [ROLE.STUDENT]
  ),

  // Resource
  new pageRoute(
    PAGE_PATH.UPLOAD,
    UploadResourcePage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.TA, ROLE.ADMIN]
  ),

  // Payment
  new pageRoute(PAGE_PATH.ORDER, OrderPage, false, false, LAYOUT_LIST.BASIC, [
    ROLE.STUDENT,
  ]),
  new pageRoute(
    PAGE_PATH.ORDER_RESULTS(),
    OrderResultsPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.STUDENT, ROLE.TA]
  ),
  new pageRoute(
    PAGE_PATH.ORDER_RESULTS_FAILED,
    OrderResultsFailedPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.STUDENT]
  ),
  new pageRoute(
    PAGE_PATH.ORDER_RESULTS_PROCESS,
    OrderResultsProcessPage,
    false,
    true,
    LAYOUT_LIST.BASIC,
    [ROLE.STUDENT]
  ),

  // Profile
  new pageRoute(PAGE_PATH.PROFILE, ProfilePage, false, false),

  // Course
  new pageRoute(
    PAGE_PATH.MY_COURSE(),
    MyCoursePage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.STUDENT, ROLE.TA]
  ),
  new pageRoute(
    PAGE_PATH.COURSE_MANAGEMENT,
    CourseManagementPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.ADMIN]
  ),
  new pageRoute(
    PAGE_PATH.COURSE_STATISTIC,
    CourseStatisticPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.ADMIN]
  ),

  //Course Working Shifts CourseWorkingShiftsPage
  new pageRoute(
    PAGE_PATH.COURSE_WORKING_SHIFTS(),
    CourseWorkingShiftsPage,
    false,
    true,
    LAYOUT_LIST.BASIC,
    [ROLE.STUDENT]
  ),

  // Course management
  new pageRoute(
    PAGE_PATH.COURSE_MANAGEMENT_DASHBOARD(),
    CourseManagementDashboard,
    false,
    false,
    LAYOUT_LIST.WITH_SIDEBAR,
    [ROLE.ADMIN, ROLE.TA]
  ),

  // TA
  new pageRoute(
    PAGE_PATH.TA_MANAGEMENT,
    TAManagementPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.ADMIN]
  ),
  new pageRoute(
    PAGE_PATH.TA_COURSES,
    TACoursesPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.TA]
  ),

  // Student
  new pageRoute(
    PAGE_PATH.STUDENT_MANAGEMENT,
    StudentManagementPage,
    false,
    false,
    LAYOUT_LIST.BASIC,
    [ROLE.ADMIN]
  ),

  // Learning
  new pageRoute(
    PAGE_PATH.COURSE_LEARNING(),
    CourseLearningPage,
    false,
    false,
    LAYOUT_LIST.LEARNING_LAYOUT,
    [ROLE.TA, ROLE.STUDENT, ROLE.ADMIN]
  ),

  //appointment
  new pageRoute(
    PAGE_PATH.TA_MANAGE_SHIFT,
    TAManageShift,
    false,
    true,
    LAYOUT_LIST.BASIC,
    [ROLE.TA]
  ),
  new pageRoute(
    PAGE_PATH.MY_APPOINTMENT,
    MyAppointment,
    false,
    true,
    LAYOUT_LIST.BASIC,
    [ROLE.TA, ROLE.STUDENT]
  ),

  // Comment report management
  new pageRoute(
    PAGE_PATH.COMMENT_REPORT_MANAGEMENT,
    CommentReportManagement,
    false,
    false
  ),
  // note
  new pageRoute(PAGE_PATH.NOTE_MANAGEMENT(), NoteManagementPage, false, false),
];

// Public
const publicRoutes = [
  new pageRoute(PAGE_PATH.HOME, HomePage, false, false),
  new pageRoute(PAGE_PATH.RESET_PASSWORD, ResetPasswordPage, false, true),
  new pageRoute(PAGE_PATH.REGISTER_TA, RegisterTAPage, false, true),
  new pageRoute(PAGE_PATH.COURSE_DETAIL(), CourseDetailPage, false, false),
  new pageRoute(PAGE_PATH.NOT_FOUND, NotFoundPage, false, true),
  new pageRoute(PAGE_PATH.GUIDE_TA, GuideTAPage, false, true),
  new pageRoute(
    PAGE_PATH.SEARCH_COURSE_RESULT,
    SearchCourseResultPage,
    false,
    false
  ),
];

export { protectedRoutes, publicRoutes };
