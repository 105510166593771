import React, { useContext, useState } from "react";

const NotificationContext = React.createContext();
function NotificationContextProvider(props) {
  const [notiList, setNotiList] = useState([]);
  const value = { notiList, setNotiList };
  return (
    <NotificationContext.Provider
      value={value}
      {...props}
    ></NotificationContext.Provider>
  );
}
const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (typeof context === "undefined")
    throw new Error(
      "useSocketContext must be used within SocketContextProvider"
    );
  return context;
};
export { NotificationContextProvider, useNotificationContext };
