let DOMAIN = process.env.REACT_APP_FRONTEND_DOMAIN_DEV;
if (!window.location.hostname.includes("localhost")) {
  DOMAIN = process.env.REACT_APP_FRONTEND_DOMAIN;
} else if (window.location.hostname.includes("onrender")) {
  DOMAIN = process.env.REACT_APP_ONRENDER_DOMAIN;
}

const PAGE_PATH = {
  BASE: DOMAIN,
  HOME: "/",
  NOT_FOUND: "/not-found",

  // Auth
  LOGIN: "/sign-in",
  REGISTER: "/sign-up",
  VERIFY_EMAIL: "/verification/verify-email",
  RESET_PASSWORD: "/forgot-password/reset",

  // Meeting
  MEETING: "/meeting",
  ROOM: "/room",
  RATE_MEETING: "/rate-meeting",

  // Resource
  UPLOAD: "/upload",

  // Payment
  ORDER: "/order",
  ORDER_RESULTS_PROCESS: "/order-results-process",
  ORDER_RESULTS: (orderId) =>
    orderId === undefined ? "/order-results/:id" : `/order-results/${orderId}`,
  ORDER_RESULTS_FAILED: "/order-results-failed",

  // Profile
  PROFILE: "/profile",

  // Course
  COURSE_DETAIL: (id) =>
    id === undefined ? "/course-detail/:id" : `/course-detail/${id}`,
  MY_COURSE: (id) => (id === undefined ? "/my-course/:id" : `/my-course/${id}`),
  COURSE_MANAGEMENT: "/course-management",
  COURSE_WORKING_SHIFTS: (courseId) =>
    courseId === undefined
      ? "/course-working-shifts/:id"
      : `/course-working-shifts/${courseId}`,
  SEARCH_COURSE_RESULT: "/search-course",

  // Course management
  COURSE_MANAGEMENT_DASHBOARD(courseId) {
    return courseId
      ? `${this.COURSE_MANAGEMENT}/${courseId}`
      : `${this.COURSE_MANAGEMENT}/:id`;
  },
  // Course management
  COURSE_STATISTIC(courseId) {
    return courseId ? `statistic/${courseId}` : `statistic/:id`;
  },

  // TA
  REGISTER_TA: "/invitation/teaching-assistant/join",
  TA_MANAGEMENT: "/ta-management",
  TA_COURSES: "/ta-courses",
  GUIDE_TA: "/guide-to-becoming-tutor",

  // Student
  STUDENT_MANAGEMENT: "/student-management",

  // Study
  COURSE_LEARNING: (courseId) =>
    courseId === undefined ? "/learning/:id" : `/learning/${courseId}`,

  // Appointment
  TA_MANAGE_SHIFT: "/ta-manage-shift",
  MY_APPOINTMENT: "/my-appointment",

  // Comment report management
  COMMENT_REPORT_MANAGEMENT: "/comment-report-management",

  //note
  NOTE_MANAGEMENT: (courseId) =>
    courseId === undefined
      ? "/note-management/:courseId"
      : `/note-management/${courseId}`,
};

export { PAGE_PATH };
