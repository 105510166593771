import React, { useContext, useState } from "react";

const SocketContext = React.createContext();
function SocketContextProvider(props) {
  const [socket, setSocket] = useState(null);
  const value = { socket, setSocket };
  return (
    <SocketContext.Provider value={value} {...props}></SocketContext.Provider>
  );
}
const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (typeof context === "undefined")
    throw new Error(
      "useSocketContext must be used within SocketContextProvider"
    );
  return context;
};
export { SocketContextProvider, useSocketContext };
